import React from 'react';
import ReactDOM from 'react-dom';
import app from "../reducers";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { historyMiddleware } from "../Middlewares/History.middleware";
import { Provider } from 'react-redux';
import ArticleBlockContainer from '../../Scripts/Components/ArticleBlock.container';

const preloadState = window.__litium.preloadState || {};
const store = createStore(
    app,
    preloadState,
    composeWithDevTools(applyMiddleware(thunk, historyMiddleware))
);

export const GreatItComponents = () => {
    if (document.getElementsByClassName('articleBlock').length > 0) {
        Array.from(document.getElementsByClassName('articleBlock')).forEach((block) => {
            const blockId = block.parentElement.getAttribute("data-litium-block-id");
            if (blockId !== "") {
                ReactDOM.render(
                    <Provider store={store}>
                        <ArticleBlockContainer {...{ blockId }} />
                    </Provider>,
                    block
                );
            }
        });
    }
}
