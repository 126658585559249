import React from 'react';
import { connect } from 'react-redux';
import { fetchArticleModel } from '../Actions/ArticleBlock.action';
import ArticleBlock from './ArticleBlock';

class ArticleBlockContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchData(this.props.blockId);
    }
    render() {
        return (
            <ArticleBlock {...this.props} />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchData: (blockId) => dispatch(fetchArticleModel(blockId))
    }

}
const mapStateToProps = state => {
    return {
        result: state.ArticleReducer.result
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArticleBlockContainer);