import { post } from './http';

export const getOrderRowDeliveryInfo = async (orderId, orderRowId) => {
    return await fetch(`/api/orderdelivery/${orderId};${orderRowId}`, {
        method: 'GET',
    }).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
};

export const markAsDelivered = async (orderId, orderRowId, quantity) => {
    //Error msg does not comply with Litium's post method
    //const response = await post(`/api/orderdelivery/${orderId};${orderRowId}`, undefined);
    return await fetch(`/api/orderdelivery/${orderId};${orderRowId}?quantity=${(!!quantity ? quantity.toFixed(0) : 1)}`, {
        method: 'POST',
        body: undefined,
    }).then(response => response.ok ? response.text() : response.text().then(text => { throw new Error(text) }))
};

export const getDeliveryReport = async (fromDate, toDate) => {
    return await fetch(`/api/orderdelivery/to/deliver?FromDate=${fromDate}&ToDate=${toDate}`, {
        method: 'GET',
    }).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
};