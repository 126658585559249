import { post } from './http';

export const getPerEventSalesReport = async (fromDate, toDate) => {
    return await fetch(`/api/ordersales/PerEventSales?FromDate=${fromDate}&ToDate=${toDate}`, {
        method: 'GET',
    }).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
};

export const getProductSalesReport = async (fromDate, toDate) => {
    return await fetch(`/api/orderdelivery/sales?FromDate=${fromDate}&ToDate=${toDate}`, {
        method: 'GET',
    }).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
};

export const getBookingReport = async (fromDate, toDate, channelSystemId) => {
    return await fetch(`/api/ordersales/Bookings?FromDate=${fromDate}&ToDate=${toDate}&ChannelSystemId=${channelSystemId}`, {
        method: 'GET',
    }).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
};