import { get } from "../Services/http";
import { catchError } from "../Actions/Error.action";

export const ARTICLE_BLOCK_RECEIVE = "ARTICLE_BLOCK_RECEIVE ";
export const ARTICLE_BLOCK_ERROR = 'ARTICLE_BLOCK_ERROR';

export const fetchArticleModel = (id) => (dispatch) => {
    return get('/api/articleblock/' + id)
        .then(response => response.json())
        .then(result => dispatch(receive(result)))
        .catch(ex => dispatch(catchError(ex, error => setError(error))));
}

export const receive = result => ({
    type: ARTICLE_BLOCK_RECEIVE,
    payload: result
})

export const setError = error => ({
    type: ARTICLE_BLOCK_ERROR,
    payload: {
        error,
    },
})