import React, { Fragment } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { translate } from '../Services/translation';
import { v4 as uuidv4 } from 'uuid';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1024: { items: 3 },
};

const ArticleBlock = (props) => {
    const block = props.result.find(x => x.blockId === props.blockId);
    const getArticles = (article) => {
        const result = [];
        article.forEach(x => {
            result.push(
                <div key={uuidv4()} className="articleBlock__itemContainer">
                    <div className="articleBlock__itemContainer--main-container">
                        <a href={x.linkUrl} className='articleBlock__itemContainer__carousel--item'>
                            {x.imageViewModel ?
                                <div className='articleBlock__carousel-item--image'>
                                    <img src={x.imageViewModel.url} alt={x.imageViewModel.alt} onDragStart={handleDragStart} role="presentation" />
                                </div>
                                :
                                <div className='articleBlock__carousel-item--image'>
                                    <img onDragStart={handleDragStart} role="presentation" className="articleBlock__carousel-item--image--defualt" />
                                </div>
                            }
                            <div className='articleBlock__carousel-item--textContainer'>
                                {x.bodyTitle && <h4 className='articleBlock__carousel-item--title'>{x.bodyTitle}</h4>}
                                {x.bodySubTitle && <p className='articleBlock__carousel-item--subtitle' dangerouslySetInnerHTML={{ __html: x.bodySubTitle }}></p>}
                            </div>
                        </a>
                        <div className="articleBlock__carousel-item--readMore-container">
                            <a href={x.linkUrl}>{translate("articles-read-more")}</a>
                        </div>
                    </div>
                </div>
            )
        })
        return result;
    }

    return (
        block && block.items?.length > 1 ?
            <div className='grid-system grid-system-within-boundaries'>
                <div className='grid-full-width'>
                    <div className='articleBlock'>
                        <div className='articleBlock__header'>
                            {block.smallTitle &&
                                <p className='articleBlock__header--subTitle'>{block.smallTitle}</p>
                            }
                            {block.title &&
                                <h3 className='articleBlock__header--title'>{block.title}</h3>
                            }
                        </div>
                        <div className='articleBlock__carousel'>
                            <AliceCarousel
                                responsive={responsive}
                                mouseTracking
                                items={getArticles(block.items)}
                                infinite
                                renderNextButton={() => {
                                    return (
                                        <div className='articleBlock__carousel-next'>
                                            <span></span>
                                        </div>
                                    );
                                }}
                                renderPrevButton={() => {
                                    return (
                                        <div className='articleBlock__carousel-prev'>
                                            <span></span>
                                        </div>
                                    );
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
            : block && block.items?.length === 1 ?
                < div className='grid-system grid-system-within-boundaries' >
                    <div className='grid-full-width'>
                        <div className='articleBlock'>
                            <div className='articleBlock__header'>
                                {block.smallTitle &&
                                    <p className='articleBlock__header--subTitle'>{block.smallTitle}</p>
                                }
                                {block.title &&
                                    <h3 className='articleBlock__header--title'>{block.title}</h3>
                                }
                            </div>
                            <div className='articleBlock__no-carousel'>
                                {getArticles(block.items)}
                            </div>
                        </div>
                    </div>
                </div >
                :
                <Fragment></Fragment>
    )
}
export default ArticleBlock;