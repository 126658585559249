import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { translate } from '../Services/translation';
import { useSelector, useDispatch } from 'react-redux';
import { toggle } from '../Actions/Cart.action';
import { reloadPayment } from '../Actions/Checkout.action';
import { update } from '../Actions/Cart.action';

const mapCartToAbort = {};

const quantityDispatch = (articleNumber, quantity, rowSystemId, additionalInfo, dispatch) => {
  mapCartToAbort[articleNumber] = new AbortController();
  dispatch(
      update(articleNumber, quantity, rowSystemId, additionalInfo, mapCartToAbort[articleNumber])
  ).then(() => {
    dispatch(reloadPayment());
  });
};

const MiniCart = () => {
  const dispatch = useDispatch();
  const [removingRow, setRemovingRow] = useState({});
  const cart = useSelector((state) => state.cart);
  const [orderRows, setOrderRows] = useState(cart?.orderRows);
  const { discountRows } = cart;
  const { quantity, orderTotal, checkoutUrl, showInfo } = useSelector(
    (state) => state.cart
  );
  const onToggle = () => dispatch(toggle());

  useEffect(() => {
    setOrderRows(cart?.orderRows);
  }, [cart]);

  useEffect(() => {}, [cart, orderRows]);

  const removeOrderRow = useCallback(
      (articleNumber, rowSystemId, additionalInfo) =>
          dispatch(update(articleNumber, 0, rowSystemId, additionalInfo)).then(() =>
        dispatch(reloadPayment())
      ),
    [dispatch]
  );

  const handleQuantityInput = useCallback(
    (articleNumber, quantity, rowSystemId, additionalInfo, ensureCorrectness = true) => {
      // In case of onBlur event, we need ensureCorrectness as true in order to ensure if the value is a valid float number
      // In case of onChange event, we accept invalid float number, but we don't send any request to the server.
      mapCartToAbort[articleNumber] && mapCartToAbort[articleNumber].abort();
      let floatQuantity = parseFloat(quantity);
      let validValue = !isNaN(floatQuantity) && floatQuantity > 0;
      if (ensureCorrectness) {
        quantity = validValue ? floatQuantity : 1;
        floatQuantity = quantity;
        validValue = true;
      }
      const index = orderRows.findIndex(
        (item) => item.rowSystemId === rowSystemId
      );
      const oldQuantity = parseFloat(cart.orderRows[index].quantity);
      if (floatQuantity !== oldQuantity && validValue) {
        quantityDispatch(articleNumber, quantity, rowSystemId, additionalInfo, dispatch);
      }
      const tempOrderRows = [...orderRows];
      tempOrderRows[index] = {
        ...tempOrderRows[index],
        quantity,
      };
      setOrderRows(tempOrderRows);
    },
    [dispatch, orderRows, cart.orderRows]
  );

  const ProductImage = useCallback(({ order }) => {
    return (
      <img
        className="checkout-cart__image"
        src={order.image}
        alt={order.name}
      />
    );
  }, []);

  const ProductName = useCallback(({ order }) => {
    const properties = JSON.parse(order.additionalInfo.json);
    const {
      productTypeId: itemTypeId,
      productType: itemType,
      selectedDateFrom: fromDate,
      selectedDateTo: toDate,
    } = properties;
    return (
      <Fragment>
        {itemType === 'Bookable' ? (
          <div className="miniCartAccomodationItem">
            <a href={order.url}>{order.name}</a>
            <span>{`Från: ${fromDate}`}</span>
            <span>{`Till: ${toDate}`}</span>
          </div>
        ) : (
          <a href={order.url}>{order.name}</a>
        )}

        <span className="checkout-cart__brand-name">{order.brand}</span>
      </Fragment>
    );
  }, []);

  const ProductDetails = useCallback(({ order }) => {
    return (
      <Fragment>
        <span className="checkout-cart__brand-name">{order.name}</span>
      </Fragment>
    );
  }, []);

  const ProductPrice = useCallback(({ order }) => {
    return (
      <Fragment>
        {order.campaignPrice ? (
          <Fragment>
            <div className="checkout-cart__campaign-price">
              {order.campaignPrice}
            </div>
            <div className="checkout-cart__original-price">
              {' '}
              ({order.price})
            </div>
          </Fragment>
        ) : (
          order.price
        )}
      </Fragment>
    );
  }, []);

  const ProductQuantity = useCallback(
    (order) => {
      const properties = JSON.parse(order.additionalInfo.json);
      const {
        productTypeId: itemTypeId,
        productType: itemType,
        selectedDateFrom: fromDate,
        selectedDateTo: toDate,
      } = properties;
      return (
        <Fragment>
          {order.isFreeGift ? (
            <div>{order.quantity}</div>
          ) : (
            <>
              {itemType === 'Bookable' ? (
                <>
                  <input
                    className="checkout-cart__input accomodation-quantity"
                    disabled
                    type="number"
                    min="1"
                    maxLength={3}
                    value={order.quantity.toString()}
                    onChange={(event) =>
                      handleQuantityInput(
                        order.articleNumber,
                        event.target.value,
                        order.rowSystemId,
                        order.additionalInfo.json,
                        false
                      )
                    }
                    onBlur={(event) =>
                      handleQuantityInput(
                        order.articleNumber,
                        event.target.value,
                        order.rowSystemId,
                        order.additionalInfo.json,
                        false
                      )
                    }
                  />
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      order.quantity--;
                      handleQuantityInput(
                        order.articleNumber,
                        order.quantity--,
                        order.rowSystemId,
                        order.additionalInfo.json,
                        false
                      );
                    }}
                  >
                    -
                  </button>

                  <input
                    className="checkout-cart__input"
                    type="number"
                    min="1"
                    maxLength={3}
                    value={order.quantity.toString()}
                    onChange={(event) =>
                      handleQuantityInput(
                        order.articleNumber,
                        event.target.value,
                        order.rowSystemId,
                        order.additionalInfo.json,
                        false
                      )
                    }
                    onBlur={(event) =>
                      handleQuantityInput(
                        order.articleNumber,
                        event.target.value,
                        order.rowSystemId,
                        order.additionalInfo.json,
                        false
                      )
                    }
                  />
                  <button
                    onClick={() => {
                      order.quantity++;
                      handleQuantityInput(
                        order.articleNumber,
                        order.quantity++,
                        order.rowSystemId,
                        order.additionalInfo.json

                      );
                    }}
                  >
                    +
                  </button>
                </>
              )}
            </>
          )}
        </Fragment>
      );
    },
    [handleQuantityInput]
  );

  const ProductTotalPrice = ({ order }) => {
    return (
      <Fragment>
        {order.totalCampaignPrice ? (
          <Fragment>
            <div className="checkout-cart__campaign-price">
              {order.totalCampaignPrice}
            </div>
            <div className="checkout-cart__original-price">
              {' '}
              ({order.totalPrice})
            </div>
          </Fragment>
        ) : (
          order.totalPrice
        )}
      </Fragment>
    );
  };

  const RemoveBtn = useCallback(
    ({ order }) => {
      return (
        <Fragment>
          {!order.isFreeGift && !removingRow[order.rowSystemId] && (
            <a
              className="table__icon table__icon--delete"
              onClick={() =>
                setRemovingRow({
                  ...removingRow,
                  [order.rowSystemId]: true,
                })
              }
              title={translate('general.remove')}
            ></a>
          )}
          {!order.isFreeGift && removingRow[order.rowSystemId] && (
                  <Fragment>
              <a
                className="table__icon table__icon--accept"
                onClick={() =>
                    removeOrderRow(order.articleNumber, order.rowSystemId, order.additionalInfo.json )
                }
                title={translate('general.ok')}
              ></a>
              <a
                className="table__icon table__icon--cancel"
                onClick={() =>
                  setRemovingRow({
                    ...removingRow,
                    [order.rowSystemId]: false,
                  })
                }
                title={translate('general.cancel')}
              ></a>
            </Fragment>
          )}
        </Fragment>
      );
    },
    [removeOrderRow, removingRow]
  );

  const CartTotal = useCallback(({ cart }) => {
    return (
      <h3 className="text--right">
        {translate('checkout.cart.total')}: {cart.orderTotal}
      </h3>
    );
  }, []);

  return (
    <div className="cart cart--mini">
      <a className="cart__link--block" onClick={() => onToggle()}>
        <i className="cart__icon">
          <span className="cart__quantity">{quantity}</span>
        </i>
      </a>
      <div className={`cart__info ${!showInfo ? 'cart__info--hidden' : ''}`}>
        <span className="cart__close-button" onClick={() => onToggle()}></span>

        <div className="row checkout__container">
          <div className={'checkout-icon'}></div>
          <div className="small-12 simple-table">
            <div className="row small-unstack no-margin miniCart__top">
              <div className="columns small-5 medium-5 large-5">Produkt</div>
              <div className="columns large-2 miniCart__topSinglePrice">
                {translate('checkout.cart.header.price')}
              </div>
              <div className="columns large-2">
                {translate('checkout.cart.header.quantity')}
              </div>
              <div className="columns large-3">
                {translate('checkout.cart.header.total')}
              </div>
            </div>
            {orderRows.map((order) => (
              <div
                className="row small-unstack no-margin checkout-cart__row miniCartOrderRow"
                key={order.rowSystemId}
              >
                <div className="columns small-5 medium-5 large-5 miniCartOrderRow__title">
                  <RemoveBtn order={order} />

                  <ProductName order={order} />
                </div>
                <div className="columns large-2 miniCartOrderRow__price miniCartOrderRow__singlePrice">
                  <ProductPrice order={order} />
                </div>
                <div className="columns large-2 simple-table__cell--no-break-word miniCartOrderRow__quantity">
                  {ProductQuantity(order)}
                </div>
                <div className="columns large-3 miniCartOrderRow__price">
                  <ProductTotalPrice order={order} />
                </div>
              </div>
            ))}
            {discountRows.map((order) => (
              <div
                className="row small-unstack no-margin checkout-cart__row"
                key={order.rowSystemId}
              >
                <div className={'row cart-single-cell'}>
                  <div className="checkout-cart__image-info columns small-12 medium-11 large-11 cart-single-cell">
                    <ProductName order={order} />
                  </div>
                </div>
                <div className={'row cart-single-cell'}>
                  <div className="checkout-cart__image-info columns small-12 medium-11 large-11 cart-single-cell">
                    <ProductDetails order={order} />
                  </div>
                </div>
                <div className="small-12 medium-6 large-6 checkout-cart__image-container cart-single-cell">
                  <ProductTotalPrice order={order} />
                </div>
              </div>
            ))}
            <div className="row small-unstack no-margin checkout-cart__row">
              <div className="columns miniCart__bottom">
                <a href={checkoutUrl} className="cart__checkout-button">
                  {translate('minicart.checkout')}
                </a>
                <CartTotal cart={cart} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
