import React, { Fragment, useEffect, useState } from 'react';
import { getPerEventSalesReport } from '../Services/Order.service'
import { translate } from '../Services/translation'

const PerEventSalesReport = ({
}) => {
    const addDays = function (date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    };

    const [data, setData] = useState(undefined);
    const [fromDate, setFromDate] = useState(addDays(new Date(), -30).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPerEventSalesReport(fromDate, toDate).then(res => { setData(res); setLoading(false) }).catch(err => { setData(undefined); setLoading(false) });
    }, [fromDate, toDate]);

    return (
        <Fragment>
            <h1>{translate('pereventreport.maintitle')}</h1>
            <label htmlFor="fromDate">{translate('pereventreport.startdate')}</label>{" "}

            <input type="date" id="fromDate" name="trip-start"
                value={fromDate}
                min="2018-01-01" max={toDate}
                onChange={event => { console.log(event.target.value); setFromDate(event.target.value) }}
            />

            {" "}
            <label htmlFor="toDate">{translate('pereventreport.enddate')}</label>{" "}

            <input type="date" id="toDate" name="trip-start"
                value={toDate}
                min={fromDate}
                onChange={event => { console.log(event.target.value); setToDate(event.target.value) }}
            />

            <br />

            {loading === false ?
                (data && data.length > 0 ?
                    (<table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th className={"order-report-header-event"}>{translate('pereventreport.title.event')}</th>
                                <th className={"order-report-header-article"}>{translate('pereventreport.title.baseproduct')}</th>
                                <th className={"order-report-header-article"}>{translate('pereventreport.title.variant')}</th>
                                <th className={"order-report-header-quantity"}>{translate('pereventreport.title.total')}</th>
                                <th className={"order-report-header-accounting-number"}>{translate('pereventreport.title.accountnr')}</th>
                                <th className={"order-report-header-price-excluding-vat"}>{translate('pereventreport.title.excludetax')}</th>
                                <th className={"order-report-header-price-vat-rate"}>{translate('pereventreport.title.tax')}</th>
                            </tr>
                        </thead>
                        {data.map((d, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <td className={"order-report-event"}>{d.event}</td>
                                        <td className={"order-report-article"}>{d.articleName}</td>
                                        <td className={"order-report-article"}>{d.variantName}</td>
                                        <td className={"order-report-quantity"}>{d.quantity}</td>
                                        <td className={"order-report-accounting-number"}>{d.accountingNumber}</td>
                                        <td className={"order-report-price-excluding-vat"}>{d.priceExcludingVat.toFixed(2)} {d.currency}</td>
                                        <td className={"order-report-price-vat-rate"}>{d.vatRate.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>)
                    : <h4>{translate('pereventreport.search.notfound')}</h4>) : <h2>{translate('pereventreport.search.loading')}</h2>}
        </Fragment>
    );
};

export default PerEventSalesReport;
