import React, { Fragment, useEffect, useState } from 'react';
import { getBookingReport } from '../Services/Order.service'
import { translate } from '../Services/translation'


const BookingReport = ({
}) => {
    const addDays = function (date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    };

    const [accBookings, setAccBookings] = useState(undefined);
    const [tentBookings, setTentBookings] = useState(undefined);
    const [activeTab, setActiveTab] = useState("allBookings");
    const [data, setData] = useState(undefined);
    const [fromDate, setFromDate] = useState(addDays(new Date(), -30).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getBookingReport(fromDate, toDate, __litium.requestContext.channelSystemId).then(res => {
            setData(res);
            setAccBookings(res.filter(item => item.accommodationType === "Accommodation"));
            setTentBookings(res.filter(item => item.accommodationType === "Tent"));
            setLoading(false)
        }).catch(err => { setData(undefined); setLoading(false) });
    }, [fromDate, toDate, activeTab]);

    const handleTab1 = () => {
        if (activeTab == "allBookings")
            return;

        setActiveTab("allBookings");
        setFromDate(addDays(new Date(), -30).toISOString().split('T')[0]);
        setToDate(new Date().toISOString().split('T')[0]);
    }
    const handleTab2 = () => {
        if (activeTab == "accBookings")
            return;

        setActiveTab("accBookings");
        setFromDate(addDays(new Date(), -30).toISOString().split('T')[0]);
        setToDate(new Date().toISOString().split('T')[0]);
    }
    const handleTab3 = () => {
        if (activeTab == "tentBookings")
            return;

        setActiveTab("tentBookings");
        setFromDate(addDays(new Date(), -30).toISOString().split('T')[0]);
        setToDate(new Date().toISOString().split('T')[0]);
    }

    const dateFormater = (e) => {
        if (e == null)
            return e;

        var month = e.split('-')[1];
        var date = e.split('-')[2].split('T')[0];

        switch (month) {
            case '01': {
                month = "Jan";
                break;
            }
            case '02': {
                month = "Feb";
                break;
            }
            case '03': {
                month = "Mar";
                break;
            }
            case '04': {
                month = "Apr";
                break;
            }
            case '05': {
                month = "Maj";
                break;
            }
            case '06': {
                month = "Jun";
                break;
            }
            case '07': {
                month = "Jul";
                break;
            }
            case '08': {
                month = "Aug";
                break;
            }
            case '09': {
                month = "Sep";
                break;
            }
            case '10': {
                month = "Okt";
                break;
            }
            case '11': {
                month = "Nov";
                break;
            }
            case '12': {
                month = "Dec";
                break;
            }
        }

        var time = date + " " + month;

        return time;
    };

    return (
        <Fragment>
            <h1 className="br-header">{activeTab === "allBookings" ? translate('bookingreport.tabs.allbookings') : activeTab === "accBookings" ? translate('bookingreport.tabs.accbookings') : translate('bookingreport.tabs.tentbookings')}</h1>
            <section>
                <div className="brTab__container">
                    <label className={activeTab === "allBookings" ? "brTab active" : "brTab"} onClick={handleTab1}>{translate('bookingreport.tabs.allbookings')}</label>
                    <label className={activeTab === "accBookings" ? "brTab active" : "brTab"} onClick={handleTab2}>{translate('bookingreport.tabs.accbookings')}</label>
                    <label className={activeTab === "tentBookings" ? "brTab active" : "brTab"} onClick={handleTab3}>{translate('bookingreport.tabs.tentbookings')}</label>
                </div>
            </section>
            <div className="brPlaceholder"></div>
            <section>
                <label htmlFor="fromDate">{translate('bookingreport.startdate')}</label>{" "}

                <input type="date" id="fromDate" name="trip-start"
                    value={fromDate}
                    min="2018-01-01" max={toDate}
                    onChange={event => { setFromDate(event.target.value) }}
                />

                {" "}
                <label htmlFor="toDate">{translate('bookingreport.enddate')}</label>{" "}

                <input type="date" id="toDate" name="trip-start"
                    value={toDate}
                    min={fromDate}
                    onChange={event => { setToDate(event.target.value) }}
                />

                <br />
                <div className="brPlaceholder"></div>
                <div className="brContent__container">
                    <div className={activeTab === "allBookings" ? "brContent brAll active" : "brContent brAll"}>
                        {loading === false ?
                            (data && data.length > 0 ?
                                (<table className={"brContent__table"} style={{ width: '100%' }}>
                                    <thead className={"brContent__thead"}>
                                        <tr className={"brContent__thead_tr"}>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_customer"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.customer')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.eventid')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_article-number"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.articlenr')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.product')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.checkin')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.checkout')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.producttype')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.teamname')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.associationname')}</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    {data.map((d, index) => {
                                        return (
                                            <tbody key={index} className={"brContent__tbody"} style={index % 2 == 0 ? { backgroundColor: "rgb(236, 237, 241)" } : { backgroundColor: "white" }}>
                                                <tr className={"brContent__tbody_tr"}>
                                                    {d.firstName != null ? 
                                                        <td className={"brContent__tbody_tr_td_article priVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.firstName}</p>
                                                                <p className={"tbody_tr_td_article_field"}>{d.lastName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block"}}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block" }}>{d.email}</p>
                                                            </div>
                                                        </td>
                                                        :
                                                        <td className={"brContent__tbody_tr_td_article brContent__tbody_td busVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.companyName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block" }}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block" }}>{d.email}</p>
                                                            </div>
                                                        </td> 
                                                    }
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.eventId}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.variantName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.productName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{dateFormater(d.checkInDate)}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{dateFormater(d.checkOutDate)}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.accommodationType}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.teamName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.associationName}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>)
                                : <h4>{translate('bookingreport.search.notfound')}</h4>) : <h2>{translate('bookingreport.search.loading')}</h2>}
                    </div>
                    <div className={activeTab === "accBookings" ? "brContent brAcc active" : "brContent brAcc"}>
                        {loading === false ?
                            (accBookings && accBookings.length > 0 ?
                                (<table className={"brContent__table"} style={{ width: '100%' }}>
                                    <thead className={"brContent__thead"}>
                                        <tr className={"brContent__thead_tr"}>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_customer"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.customer')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.eventId')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_article-number"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.articlenr')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.product')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.checkin')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.checkout')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.teamname')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.associationname')}</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    {accBookings.map((d, index) => {
                                        return (
                                            <tbody key={index} className={"brContent__tbody"} style={index % 2 == 0 ? { backgroundColor: "rgb(236, 237, 241)" } : { backgroundColor: "white" }}>
                                                <tr className={"brContent__tbody_tr"}>
                                                    {d.firstName != null ? 
                                                        <td className={"brContent__tbody_tr_td_article priVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.firstName}</p>
                                                                <p className={"tbody_tr_td_article_field"}>{d.lastName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block"}}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block"}}>{d.email}</p>
                                                            </div>
                                                        </td>
                                                        :
                                                        <td className={"brContent__tbody_tr_td_article brContent__tbody_td busVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.companyName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block" }}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block" }}>{d.email}</p>
                                                            </div>
                                                        </td> 
                                                    }
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.eventId}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.variantName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.productName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{dateFormater(d.checkInDate)}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{dateFormater(d.checkOutDate)}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.teamName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.associationName}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                )
                            }
                             </table>)
                                : <h4>{translate('bookingreport.search.notfound')}</h4>) : <h2>{translate('bookingreport.search.loading')}</h2>}
                    </div>
                    <div className={activeTab === "tentBookings" ? "brContent brTent active" : "brContent brTent"}>
                        {loading === false ?
                            (tentBookings && tentBookings.length > 0 ?
                                (<table className={"brContent__table"}  style={{ width: '100%' }}>
                                    <thead className={"brContent__thead"}>
                                        <tr className={"brContent__thead_tr"}>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_customer"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.customer')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.eventId')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article brContent__thead_tr_th_article-number"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.articlenr')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.product')}</p>
                                                </div>
                                            </th>
                                            <th className={"brContent__thead_tr_th_article"}>
                                                <div className="thead_tr_th_article_field-container">
                                                    <p className="thead_tr_th_article_field">{translate('bookingreport.title.associationname')}</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    {tentBookings.map((d, index) => {
                                        return (
                                            <tbody key={index} className={"brContent__tbody"} style={index % 2 == 0 ? { backgroundColor: "rgb(236, 237, 241)" } : { backgroundColor: "white" }}>
                                                <tr className={"brContent__tbody_tr"}>
                                                    {d.firstName != null ?
                                                        <td className={"brContent__tbody_tr_td_article priVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.firstName}</p>
                                                                <p className={"tbody_tr_td_article_field"}>{d.lastName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block" }}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block" }}>{d.email}</p>
                                                            </div>
                                                        </td>
                                                        :
                                                        <td className={"brContent__tbody_tr_td_article brContent__tbody_td busVer"}>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_customer">
                                                                <p className={"tbody_tr_td_article_field"}>{d.companyName}</p>
                                                            </div>
                                                            <div className="tbody_tr_td_article_field-container tbody_tr_td_article_contact">
                                                                <p className={"tbody_tr_td_article_field"} style={d.phoneNumber == null ? { display: "none" } : { display: "block" }}>{d.phoneNumber}</p>
                                                                <p className={"tbody_tr_td_article_field"} style={d.email == null ? { display: "none" } : { display: "block" }}>{d.email}</p>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.eventId}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.variantName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.productName}</p>
                                                        </div>
                                                    </td>
                                                    <td className={"brContent__tbody_tr_td_article"}>
                                                        <div className="tbody_tr_td_article_field-container">
                                                            <p className={"tbody_tr_td_article_field"}>{d.associationName}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                    )
                                    }
                                </table>)
                                : <h4>{translate('bookingreport.search.notfound')}</h4>) : <h2>{translate('bookingreport.search.loading')}</h2>}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};



export default BookingReport;