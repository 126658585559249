import React, { Fragment, useEffect, useState } from 'react';
import { getProductSalesReport } from '../Services/Order.service'
import { translate } from '../Services/translation'

const ProductSalesReport = ({
}) => {
    const [data, setData] = useState(undefined);
    const [fromDate, setFromDate] = useState(addDays(new Date(), -10).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data) {
        }
    }, [data]);

    useEffect(() => {
        setLoading(true);
        getDeliveryReport(fromDate, toDate).then(res => { setData(res); setLoading(false) }).catch(err => { setData(undefined); setLoading(false) });
    }, [fromDate, toDate]);

    const addDays = function (date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    };

    return (
        <Fragment>
            <h1>{translate('productsalereport.maintitle')}</h1>
            <label htmlFor="fromDate">{translate('productsalereport.startdate')}</label>{" "}

            <input type="date" id="fromDate" name="trip-start"
                value={fromDate}
                min="2018-01-01" max={toDate}
                onChange={event => { console.log(event.target.value); setFromDate(event.target.value) }}
            />

            {" "}
            <label htmlFor="toDate">{translate('productsalereport.enddate')}</label>{" "}

            <input type="date" id="toDate" name="trip-start"
                value={toDate}
                min={fromDate}
                onChange={event => { console.log(event.target.value); setToDate(event.target.value) }}
            />

            <br />

            {loading === false ?
                (data && data.length > 0 ?
                    (<table style={{ width: '100%' }}>
                        <tr>
                            <th className={"delivery-report-header-date"}>{translate('productsalereport.title.date')}</th>
                            <th className={"delivery-report-header-article-type"}>{translate('productsalereport.title.articletype')}</th>
                            <th className={"delivery-report-header-article-number"}>{translate('productsalereport.title.articlenr')}</th>
                            <th className={"delivery-report-header-description"}>{translate('productsalereport.title.description')}</th>
                            <th className={"delivery-report-header-quantity"}>{translate('productsalereport.title.total')}</th>
                            <th className={"delivery-report-header-accounting-number"}>{translate('productsalereport.title.accountnr')}</th>
                            <th className={"delivery-report-header-price-excluding-vat"}>{translate('productsalereport.title.excludetax')}</th>
                            <th className={"delivery-report-header-price-vat-rate"}>{translate('productsalereport.title.tax')}</th>
                        </tr>
                        {data.map(d => {
                            return (
                                <tr>
                                    <td className={"delivery-report-date"}>{d.date.split('T')[0]}</td>
                                    <td className={"delivery-report-article-type"}>{d.productTypeId}</td>
                                    <td className={"delivery-report-article-number"}>{d.articleNumber}</td>
                                    <td className={"delivery-report-description"}>{d.baseProductDescription}<br />{d.description}</td>
                                    <td className={"delivery-report-quantity"}>{d.quantity}</td>
                                    <td className={"delivery-report-accounting-number"}>{d.accountingNumber}</td>
                                    <td className={"delivery-report-price-excluding-vat"}>{d.priceExcludingVat.toFixed(2)} {d.currency}</td>
                                    <td className={"delivery-report-price-vat-rate"}>{d.vatRate.toFixed(2)}</td>
                                </tr>
                            )
                        })}
                    </table>)
                    : <h4>{translate('productsalereport.search.notfound')}</h4>) : <h2>{translate('productsalereport.search.loading')}</h2>}
        </Fragment>
    );
};

export default ProductSalesReport;
