import React, {Fragment, useEffect, useState} from 'react';
import { QrReader } from 'react-qr-reader';
import {getOrderRowDeliveryInfo, markAsDelivered} from '../Services/Delivery.service'
import Swal from 'sweetalert2';
import NumericInput from 'react-numeric-input';

const QRScanner = ({}) => {
    const [orderAndOrderRowIds, setOrderAndOrderRowIds] = useState(undefined);
    const [orderRowInfo, setOrderRowInfo] = useState(undefined);
    const [quantityToMarkAsDelivered, setQuantityToMarkAsDelivered] = useState(1);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        getOrderAndOrderRowInfo();
    },[orderAndOrderRowIds]);

    const getOrderAndOrderRowInfo = () => {
        if(orderAndOrderRowIds){
            if(orderAndOrderRowIds[0] && orderAndOrderRowIds[1]) {
                setLoading(true);
                getOrderRowDeliveryInfo(orderAndOrderRowIds[0], orderAndOrderRowIds[1])
                    .then(res => {
                        setOrderRowInfo(res);
                        if (quantityToMarkAsDelivered > res.quantityLeftToShip && quantityToMarkAsDelivered > 1)
                            setQuantityToMarkAsDelivered(res.quantityLeftToShip);
                        setLoading(false);
                    })
                    .catch(err => {setOrderRowInfo(undefined); showErrorDialog(err); setLoading(false);});
            }
        }
        setQuantityToMarkAsDelivered(1);
    };

    const markQuantityAsDelivered = () => {
        if(orderAndOrderRowIds && orderRowInfo){
            if(orderAndOrderRowIds[0] && orderAndOrderRowIds[1]) {
                setLoading(true);
                markAsDelivered(orderAndOrderRowIds[0], orderAndOrderRowIds[1], quantityToMarkAsDelivered)
                    .then(res => {showSuccessDialog(res); getOrderAndOrderRowInfo(); setLoading(false);})
                    .catch(err => { showErrorDialog(err); setLoading(false);});
            } else {
                showBadQrCodeDialog();
            }
        }
    };

    const performReset = () => {
        setQuantityToMarkAsDelivered(1);
        setOrderRowInfo(undefined);
        setOrderAndOrderRowIds(undefined);
    };

    const showBadQrCodeDialog = (err) => {
        Swal.fire({
            icon: 'error',
            title: 'OBS!',
            text: 'Fel på QR-kod',
        });
    }
    
    const showErrorDialog = (err) => {
        Swal.fire({
            icon: 'warning',
            title: 'OBS!',
            text: err,
        });
    }

    const showSuccessDialog = (res) => {
        Swal.fire({
            icon: 'success',
            text: res ? `Order leveransmarkerad. Kvar att leverera: ${parseFloat(res).toFixed(1)}` : 'Order leveransmarkerad',
        });
    }
    
    return (
        <Fragment>
            {loading !== true ?
                (<>
                    <QrReader
                        onResult={(result, error) => {
                            try {
                                if (!!result) {
                                    setOrderAndOrderRowIds(result?.text.split(";"));
                                }
                                if (!!error) {
                                    console.info(error);
                                }
                            }
                            catch (e)
                            {
                                console.log(e);
                                setOrderAndOrderRowIds(undefined);
                            }
                        }}
                        style={{ width: '100%' }}
                        constraints={{ facingMode: 'environment' }}/>
                    <div>
                        Antal att leverera <NumericInput min={1} max={100} value={quantityToMarkAsDelivered} onChange={(value) => setQuantityToMarkAsDelivered(parseInt(value))} /><br/>
                        <button className={"scanner-button"} onClick={markQuantityAsDelivered}>Markera som levererad</button>
                        <p><b>Information orderrad:</b></p>
                        {!!orderRowInfo ? (
                            <>
                                <p>
                                    Beskrivning: {orderRowInfo.description}<br/>
                                    Antal köpta: {orderRowInfo.quantity}<br/>
                                    Kvar att leverera: {orderRowInfo.quantityLeftToShip}
                                </p>
                            </>
                        ) : ""}
                    </div>
                    <button className={"scanner-button"} onClick={performReset}>Återställ</button>
                </>) 
                : (<h2>Laddar...</h2>)}
        </Fragment>
    );
};

export default QRScanner;
