import { ARTICLE_BLOCK_RECEIVE, ARTICLE_BLOCK_ERROR } from '../Actions/ArticleBlock.action';
import { error as errorReducer } from '../Reducers/Error.reducer';

const defaultState = {
    result: []
}
export const ArticleReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ARTICLE_BLOCK_RECEIVE:
            return {
                ...state,
                result: [...state.result, action.payload],
            }
        case ARTICLE_BLOCK_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action)
            }
        default:
            return state;
    }
}